import { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import schiff from "../../assets/images/schiff2.jpeg";
import schiffWebp from "../../assets/images/schiff2.webp";
import bpjph from "../../assets/images/akkredited/bpjph.png";
import sfda from "../../assets/images/akkredited/sfda.png";
import jakim from "../../assets/images/akkredited/jakim.png";
import hak from "../../assets/images/akkredited/hak.png";
import muis from "../../assets/images/akkredited/muis.png";
import gac  from "../../assets/images/akkredited/gac.png";
import saso from "../../assets/images/acknowledged/saso.png";
import esma from "../../assets/images/acknowledged/esma.jpg";
import moiat from "../../assets/images/acknowledged/moiat.png";
import moph from "../../assets/images/acknowledged/moph.png";
import cicot from "../../assets/images/acknowledged/cicot.png";
import ahik from "../../assets/images/acknowledged/ahik.png";
const Akkreditierungen = () => {
  const { t } = useTranslation();
  return( 
    <Fragment>
      <div className="backgroundSchiff"></div>
      <div id="akkreditierungen" className="articleSeperator"></div>
      <article className="mb-5">
        <div className="container">
          <h2 className="text-center">{t('akk.title')}</h2>
          <p className="w-50 mx-auto text-center">
            {t('akk.para11')}
            <strong>{t('akk.para12')}</strong>.
          </p>

          <h3>{t('akk.subheader')}</h3>

          <div className="row row-cols-1 row-cols-lg-2 bsGutterX">
            <div className="col col-lg-7 col-xl-8">
              <div className="row" id="logoRow1">
                <div className="col-4 text-center">
                  <div>
                    <img src={bpjph} className="logo" alt="BPJPH Logo" height="120px"/>
                  </div>
                  <div className="mt-2">
                    <span className="akkreditierungStelle">BPJPH</span><br/>
                    <span className="akkreditierungName">Halal Product Assurance Organizing Body</span><br/>
                    <a href="./assets/bpjph.pdf" target="_blank" className="akkreditierungLink">{t('akk.akk')}</a><br/>
                  </div>
                </div>  
                <div className="col-4 text-center">
                  <div>
                    <img src={gac} className="logo" alt="GAC Logo" height="120px"/>
                  </div>
                  <div className="mt-2">
                    <span className="akkreditierungStelle">GAC</span><br/>
                    <span className="akkreditierungName">GCC Accreditation Center</span><br/>
                    <a href="./assets/gac.pdf" target="_blank" className="akkreditierungLink">{t('akk.akk')}</a><br/>
                    <a href="./assets/gac_hu.pdf" target="_blank" className="akkreditierungLink">{t('akk.akk_hu')}</a><br/>
                  </div>
                </div>    
                <div className="col-4 text-center">
                  <div>
                    <img src={sfda} className="img-fluid logo" alt="SFDA Logo"/>
                  </div>
                  <div className="mt-2">
                    <span className="akkreditierungStelle">SFDA</span><br/>
                    <span className="akkreditierungName">Saudi Food &<br/> Drug Authority</span><br/>
                    <a href="./assets/sfda_at.pdf" target="_blank" className="akkreditierungLink">{t('akk.akk')}</a>
                  </div>
                </div>             
              </div>
              <div className="row mt-4" id="logoRow2">
                <div className="col-4 text-center">
                  <div>
                    <img src={jakim} className="logo" alt="JAKIM Logo"/>
                  </div>
                  <div className="mt-2">
                    <span className="akkreditierungStelle">JAKIM</span><br/>
                    <span className="akkreditierungName">JABATAN KEMAJUAN <br/>ISLAM MALAYSIA</span><br/>
                    <a href="./assets/jakim.pdf" target="_blank" className="akkreditierungLink">{t('akk.akk')}</a>
                  </div>
                </div>   
                <div className="col-4 text-center">
                  <div>
                    <img src={hak} className="logo" alt="HAK Logo"/>
                  </div>
                  <div className="mt-2">
                    <span className="akkreditierungStelle">HAK</span><br/>
                    <span className="akkreditierungName">Halal Accreditation Agency</span><br/>
                    <a href="./assets/hak.pdf" target="_blank" className="akkreditierungLink">{t('akk.akk')}</a>
                  </div>
                </div>
                <div className="col-4 text-center">
                  <div>
                    <img src={muis} className="logo" alt="Muis Logo"/>
                  </div>
                  <div className="mt-2">
                    <span className="akkreditierungStelle">MUIS</span><br/>
                    <span className="akkreditierungName">Majlis Ugama Islam Singapura</span><br/>
                    <a href="./assets/muis.pdf" target="_blank" className="akkreditierungLink">{t('akk.akk')}</a>
                  </div>
                </div>
              </div>                           
           
              <div className="row mt-4" id="logoRow3">
                <div className="col-4 text-center">
                    <div>
                      <img src={saso} className="img-fluid logo" alt="SASO Logo"/>
                    </div>
                    <div className="mt-2">
                      <span className="akkreditierungStelle">SASO</span><br/>
                      <span className="akkreditierungName">Saudi Standards</span><br/>
                      <a href="./assets/saso.pdf" target="_blank" className="akkreditierungLink">{t('akk.akk')}</a>
                    </div>   
                </div>                           
              </div>

            </div>              
            <div className="col col-lg-5 col-xl-4 mt-4 mt-lg-0 px-3 pb-3 pb-lg-0 backgroundGreen rounded">
              <h4 className="pb-4">{t('akk.liheader')}</h4>
              <p className="staaten"><span className="staatenCounter">06</span>{t('akk.li1')}</p>
              <p className="staaten"><span className="staatenCounter">56</span>{t('akk.li2')}</p>
              <p className="staaten"><span className="staatenCounter">07</span>{t('akk.li3')}</p>
              <picture>
                  <source srcSet={schiffWebp} type="image/webp"/>
                  <img src={schiff} className="mt-2 img-fluid mx-auto rounded" alt="Ship"/>
                </picture>
            </div>
          </div>

          <div className="text-center pt-5 mt-5">
            <a className="toggleLink togglePartner btn btn-outline-success" data-bs-toggle="collapse" href="#collapsePartner" role="button" aria-expanded="false" aria-controls="collapsePartner">
            {t('akk.btnPartner')}
              <span className="text-collapsed"><i className="bi bi-chevron-down"></i></span>
              <span className="text-expanded"><i className="bi bi-chevron-up"></i></span>
            </a>
          </div>

          <div className="collapse" id="collapsePartner">
            <h3>{t('akk.partner')}</h3>
            <div className="row" id="logoRow4">
              <div className="col-3 text-center">
                <div>
                  <img src={esma} className="img-fluid logo" alt="ESMA Logo"/>
                </div>
                <div className="mt-2">
                  <span className="akkreditierungStelle">ESMA</span><br/>
                  <span className="akkreditierungName">Emirates Authority for Standardization & Metrology</span><br/>
                </div>
              </div>
              <div className="col-2 text-center">
                <div>
                  <img src={moiat} className="logo" alt="MOIAT Logo"/>
                </div>
                <div className="mt-2">
                  <span className="akkreditierungStelle">MOIAT</span><br/>
                  <span className="akkreditierungName">Ministry of Industry & Advanced Technology</span><br/>
                </div>
              </div>                
              <div className="col-2 text-center">
                <div>
                  <img src={moph} className="logo" alt="MOPH Logo"/>
                </div>
                <div className="mt-2">
                  <span className="akkreditierungStelle">MOPH</span><br/>
                  <span className="akkreditierungName">Qatar Ministry of Public Health</span><br/>
                </div>
              </div>                
              <div className="col-2 text-center">
                <div>
                  <img src={cicot} className="logo" alt="CICOT Logo"/>
                </div>
                <div className="mt-2">
                  <span className="akkreditierungStelle">CICOT</span><br/>
                  <span className="akkreditierungName">Central Islamic Council of Thailand</span><br/>
                </div>
              </div>
              <div className="col-2 text-center">
                <div>
                  <img src={ahik} className="logo" alt="AHIK Logo"/>
                </div>
                <div className="mt-2">
                  <span className="akkreditierungStelle">AHIK</span><br/>
                  <span className="akkreditierungName">ALE "Association Halal Industry of Kazakhstan" (AHIK)</span><br/>
                </div>
              </div>             
            </div>
          </div>
        </div>
      </article>
    </Fragment>
  )
}
export default Akkreditierungen